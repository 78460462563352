<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col :md="isTogglingView && toggledViewData ? '8' : '12'">
        <b-card>
          <b-card-text>
            <DxGrid
              title="Service Requests"
              url="/pages/services-tools/service-request/manage"
              toggleView
              toggleQuickEdit
              :data-source="dataSource"
              :total-count="totalCount"
              @onToggleView="(v) => onToggleView(v)"
              @on-delete="(v) => onDelete(v)"
              @on-update="(v) => onUpdate(v)"
              @on-update-cancel="(v) => loadServiceRequest()"
              @on-row-click="(v) => onViewDetail(v)"
              @on-search="(v) => (keyword = v)"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
            >
              <template #columns>
                <DxColumn
                  v-for="(item, index) in dataColumns"
                  :key="index"
                  :data-field="item.field"
                  :caption="item.caption"
                  :format="item.format"
                  :data-type="item.dataType"
                />
              </template>

              <template #action-buttons>
                <!-- <DxButton icon="copy" hint="Duplicate" :onClick="onDuplicate" /> -->
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        :md="isTogglingView ? '4' : '12'"
        v-if="isTogglingView && toggledViewData"
      >
        <b-card>
          <b-card-text>
            <ViewServiceRequestDetail
              v-if="isTogglingView"
              :service-request-data="toggledViewData"
            />
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import ViewServiceRequestDetail from './QuickView.vue';
import jobRequestApi from '@api/jobrequest_headers';
import usersApi from '@api/users';
import { queryParameters } from '@/schema';
import { getUserData } from '@/auth/utils';

export default {
  name: 'ServiceRequestPage',
  components: {
    ViewServiceRequestDetail,
  },
  data: () => ({
    isTogglingView: false,
    toggledViewData: null,
    dataSource: [],
    dataColumns: [
      { caption: 'Ticket No.', field: 'ticket_no' },
      { caption: 'Customer', field: 'company_name' },
      { caption: 'Priority', field: 'urgency_level' },
      { caption: 'Problem Description', field: 'description' },
      {
        caption: 'Reported Date',
        field: 'preview_report_date',
        dataType: 'datetime',
        format: 'dd/MM/yyyy hh:mm a',
      },
      {
        caption: 'Expected Date',
        field: 'preview_job_etd',
        dataType: 'datetime',
        format: 'dd/MM/yyyy hh:mm a',
      },
      { caption: 'Status', field: 'service_status' },
      { caption: 'Assigned', field: 'assigned_user' },
    ],
    totalCount: 0,
    page: 0,
    size: 0,
    keyword: null,
  }),
  computed: {
    getRole: {
      get() {
        const userData = getUserData();
        const { role } = userData;

        const roles = ['admin', 'custadmin', 'qcsemployee'];
        return roles.includes(role);
      },
    },
  },
  watch: {
    page(v) {
      this.loadServiceRequest();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadServiceRequest();
      }
    },
    keyword(v) {
      this.loadServiceRequest();
    },
  },
  mounted() {
    this.loadServiceRequest();
  },
  methods: {
    loadServiceRequest() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      if (this.keyword) {
        params.filter = this.keyword;
      }

      const userData = getUserData();
      const { companyId } = userData;

      if (companyId) {
        params.filterExt = `company_id=${companyId}`;
      }

      if (this.$route.query.company) {
        params.filterExt = `company=${this.$route.query.company}`;
      }

      jobRequestApi
        .list(params)
        .then(({ data }) => {
          if (data != null) {
            for (const item of data) {
              const { problem_description, reported_date, job_etd } = item;

              item.preview_report_date = new Date(reported_date * 1000);
              item.preview_job_etd = new Date(job_etd * 1000);

              if (problem_description) {
                item.description = `${problem_description.substring(0, 45)}...`;
              }
            }

            this.dataSource = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadAvailableTechnicians() {
      const params = {
        sort: 'first_name',
        page: { size: 1000 },
        status: 'O',
        filterExt: `roles=user&coyuser&custuser&tech&qcsemployee`,
      };

      usersApi
        .list(params)
        .then(({ data }) => {
          const items = [];

          for (let i = 0; i < data.length; i++) {
            const item = data[i];

            let fullName = item.first_name;
            if (item.last_name) {
              fullName += ` ${item.last_name}`;
            }

            items.push({ label: fullName, value: +item.id, item });
          }

          this.users = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    handleEvent(action, event) {
      this.$nextTick(() => {
        //
      });
    },
    onToggleView(event) {
      this.isTogglingView = event;

      if (!event) {
        this.toggledViewData = null;
      }
    },
    onViewDetail(event) {
      if (this.isTogglingView) {
        return;
      }

      const url = `/pages/services-tools/service-request/view?id=${event.key}`;
      this.$router.push(url);
    },
    onUpdate(event) {
      const data = event.data;
    },
    onDelete(event) {
      //
    },
    onPrintPDF(event) {
      const data = event?.row?.data;

      if (data) {
      }
    },
    onDuplicate(event) {
      this.showModal = true;
      this.loadAvailableTechnicians();
      console.log(event);
    },
  },
};
</script>

<style></style>
